import React, { memo } from "react";
import ReactMarkdown from "react-markdown";
import { Button, List, Select, SelectProps, Text, Tooltip } from "@mantine/core";
import { IconCheck, IconMessage } from "@tabler/icons-react";
import { EFontSize } from "@common/types";

interface ChatOptionsMessageProps {
  fontSize?: EFontSize;
  message: string;
  options?: string[];
  onClick: (question: string) => Promise<void>;
}

const iconProps = {
  stroke: 1.5,
  color: "currentColor",
  opacity: 0.6,
  size: 18,
};

const ChatOptionsMessage: React.FC<ChatOptionsMessageProps> = memo(
  ({ fontSize = EFontSize.base, message, options, onClick = async () => {} }) => {

    const fontSizeClassMap: Record<EFontSize, string> = {
      xs: "prose-xs",
      sm: "prose-sm",
      base: "prose-base",
      lg: "prose-lg",
      xl: "prose-xl",
    };

    const elementSize = fontSize === "base" ? "md" : fontSize;

    const renderSelectOption: SelectProps["renderOption"] = ({ option, checked }) => {
      return (
        <div className="grid grid-cols-[1.5rem_1fr_auto_auto] justify-self-center items-center gap-2 w-full">
          <Tooltip label="Send response" openDelay={1000}>
            <IconMessage size="1.2rem" />
          </Tooltip>
          <span className="pt-1">{option.label}</span>
          <div className="self-center">
            {checked && <IconCheck style={{ marginInlineStart: "auto" }} {...iconProps} />}
          </div>
        </div>
      );
    };

    return Array.isArray(options) && options.length ? (
      <div className="bg-white p-2 rounded-tr-xl rounded-br-xl rounded-bl-xl">
        <div
          className={`prose ${fontSizeClassMap[fontSize || "base"]} prose-strong:text-[--mantine-color-text] text-[color:var(--mantine-color-text)] pl-1 py-1 [overflow-wrap:anywhere]`}
        >
          <ReactMarkdown>{message}</ReactMarkdown>
        </div>
        <div className="py-4">
          {options.length <= 3 && options.every((option) => option.length <= 30) ? (
            <List spacing="sm">
              {options.map((option, index) => (
                <List.Item key={index}>
                  <Button
                    color="MediumSlateBlue"
                    variant="subtle"
                    onClick={() => onClick(option)}
                  >
                    <IconMessage size={18} className="mr-2" />
                    <Text td="underline">{option}</Text>
                  </Button>
                </List.Item>
              ))}
            </List>
          ) : (
            <Select
              label="Response options"
              size={elementSize}
              radius="md"
              placeholder="Select response"
              data={[...new Set(options)]} // Remove duplicates, otherwise UI can crash
              onChange={(value) => (value ? onClick(value) : null)}
              maxDropdownHeight={300}
              comboboxProps={{ transitionProps: { transition: "pop", duration: 100 } }}
              leftSection={<IconMessage size={18} />}
              renderOption={renderSelectOption}
            />
          )}
        </div>
      </div>
    ) : null;
  }
);

export default ChatOptionsMessage;
