import React, { memo } from "react";

import { ActionIcon, HoverCard, Indicator, Menu, ScrollArea, Tooltip } from "@mantine/core";
import { IconBriefcase, IconCheck, IconCompass, IconSettings, IconStar } from "@tabler/icons-react";

import AnthropicLogo from "./AnthropicLogo";
import Gpt4Logo from "./Gpt4Logo";
import { AvailableLLMs, ChatMode, UserSettingsUpdate } from "@common/types";
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from "@/redux/api";
import { notifications } from "@mantine/notifications";
import MistralLogo from "./MistralLogo";
import MetaLogo from "./MetaLogo";
import { ModeEnum } from "@common/types";

interface ChatSettingsProps {
  mode: ModeEnum;
}

const ChatSettings: React.FC<ChatSettingsProps> = memo(({ mode }) => {
  const { data: userSettings } = useGetUserSettingsQuery();

  const [updateUserSettings, { isLoading }] = useUpdateUserSettingsMutation();

  // TODO(Reinis): Probably the default should be set in env, or retrieved
  // from BE via an API call so that there is just one source of truth
  const defaultMarkupModelChoice = {
    label: (
      <HoverCard width={400} shadow="md" openDelay={0} closeDelay={0} offset={20}>
        <HoverCard.Target>
          <div className="flex flex-col">
            <span>Default (currently "Anthropic Claude 3.5 Sonnet")</span>
            <span className="text-xs text-green-500 font-light">RECOMMENDED</span>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <div className="p-4">
            <div className="text-lg font-semibold">Default</div>
            <div className="text-sm text-gray-600 mt-2">
              Use whichever LLM is recommended by us at the time. Our recommendations are based on
              regular evaluations of how well the LLMs perform overall on legal tasks. Response
              quality is favored over speed in our evaluations. The default LLM may change over time
              as new LLMs become available.
            </div>
          </div>
        </HoverCard.Dropdown>
      </HoverCard>
    ),
    value: null,
  };

  // TODO(Reinis): Later we might have different defaults for different tools, so this should
  // be refactored to be more flexible
  const defaultExplainModelChoice = defaultMarkupModelChoice;

  const markedAsNewList = [
    AvailableLLMs.CLAUDE_3_5_SONNET,
    AvailableLLMs.GPT_4O,
    AvailableLLMs.GPT_4O_MINI,
    AvailableLLMs.MISTRAL_LARGE_2,
    AvailableLLMs.MISTRAL_NEMO,
    AvailableLLMs.LLAMA_3_1_70B_VERSATILE,
  ];

  const markupModelChoices = [
    defaultMarkupModelChoice,
    {
      label: "Anthropic Claude 3.5 Sonnet",
      value: AvailableLLMs.CLAUDE_3_5_SONNET,
    },
    {
      label: "OpenAI GPT-4o",
      value: AvailableLLMs.GPT_4O,
    },
    {
      label: "Mistral Large 2",
      value: AvailableLLMs.MISTRAL_LARGE_2,
    },
    {
      label: "OpenAI GPT-4",
      value: AvailableLLMs.GPT_4,
    },
    {
      label: "Anthropic Claude 3 Opus",
      value: AvailableLLMs.CLAUDE_3_OPUS,
    },
  ];

  const explainModelChoices = [
    defaultExplainModelChoice,
    {
      label: "Anthropic Claude 3.5 Sonnet",
      value: AvailableLLMs.CLAUDE_3_5_SONNET,
    },
    {
      label: "OpenAI GPT-4o",
      value: AvailableLLMs.GPT_4O,
    },
    {
      label: "OpenAI GPT-4o mini",
      value: AvailableLLMs.GPT_4O_MINI,
    },
    {
      label: "Mistral Large 2",
      value: AvailableLLMs.MISTRAL_LARGE_2,
    },
    {
      label: "Llama 3.1 70B",
      value: AvailableLLMs.LLAMA_3_1_70B_VERSATILE,
    },
    {
      label: "OpenAI GPT-4",
      value: AvailableLLMs.GPT_4,
    },
    {
      label: "Anthropic Claude 3 Opus",
      value: AvailableLLMs.CLAUDE_3_OPUS,
    },
    {
      label: "Anthropic Claude 3 Sonnet",
      value: AvailableLLMs.CLAUDE_3_SONNET,
    },
    {
      label: "Anthropic Claude 3 Haiku",
      value: AvailableLLMs.CLAUDE_3_HAIKU,
    },
    {
      label: "Mistral Nemo",
      value: AvailableLLMs.MISTRAL_NEMO,
    },
  ];

  const modelIconProps = {
    width: "40",
    height: "40",
    stroke: "1.0",
  };

  const llmIcons: Record<string, React.ReactNode> = {
    gpt_4: <Gpt4Logo {...modelIconProps} />,
    gpt_4o: <Gpt4Logo {...modelIconProps} />,
    gpt_4o_mini: <Gpt4Logo {...modelIconProps} />,
    claude_3_haiku: <AnthropicLogo {...modelIconProps} />,
    claude_3_sonnet: <AnthropicLogo {...modelIconProps} />,
    claude_3_opus: <AnthropicLogo {...modelIconProps} />,
    claude_3_5_sonnet: <AnthropicLogo {...modelIconProps} />,
    mistral_large_2: <MistralLogo {...modelIconProps} />,
    mistral_nemo: <MistralLogo {...modelIconProps} />,
    "llama-3.1-70b-versatile": <MetaLogo {...modelIconProps} />,
  };

  const defaultLlmIcon = <IconStar {...modelIconProps} />;

  const explainChatModeChoices = [
    {
      label: (
        <div className="flex flex-col">
          <span>Pro Mode</span>
          <span className="text-xs text-green-500 font-light">RECOMMENDED</span>
        </div>
      ),
      value: ChatMode.PRO_MODE,
    },
    {
      label: "Discovery Mode",
      value: ChatMode.DISCOVERY_MODE,
    },
  ];

  const chatModeIcons: Record<string, React.ReactNode> = {
    [ChatMode.PRO_MODE]: <IconBriefcase {...modelIconProps} />,
    [ChatMode.DISCOVERY_MODE]: <IconCompass {...modelIconProps} />,
  };

  const chatModeHoverInfo = {
    [ChatMode.PRO_MODE]: {
      title: "Pro Mode",
      description:
        "Strict mode that puts more guardrails to the LLM to focus only on legal tasks and only on the currently opened document(s). Gives LLM less freedom and creativity, but leads to fewer hallucinations (incorrect or irrelevant responses).",
    },
    [ChatMode.DISCOVERY_MODE]: {
      title: "Discovery Mode",
      description: "Less restrictive mode that lets the LLM look up information on the Web.",
    },
  };

  const handleUpdateUserSettings = async (updatedUserSettings: UserSettingsUpdate) => {
    if (!userSettings) {
      return;
    }

    notifications.show({
      id: "UPDATE_USER_SETTINGS_IN_PROGRESS",
      loading: true,
      title: "Updating",
      message: "Updating your settings...",
    });

    updateUserSettings({ ...userSettings, ...updatedUserSettings });

    notifications.hide("UPDATE_USER_SETTINGS_IN_PROGRESS");

    notifications.show({
      title: "Settings Updated",
      message: "Your settings have been updated.",
      color: "green",
    });
  };

  const getLlmIcon = (value: AvailableLLMs | null) => {
    if (value) {
      return markedAsNewList.includes(value) ? (
        <Indicator inline size="1rem" color="violet" label="New">
          {llmIcons[value]}
        </Indicator>
      ) : (
        llmIcons[value]
      );
    } else {
      return defaultLlmIcon;
    }
  };

  return (
    <Menu width={350} shadow="md">
      <Menu.Target>
        <Tooltip label="Settings">
          <ActionIcon variant="transparent">
            <IconSettings color="white" className={`${isLoading ? "animate-spin" : ""}`} />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        {mode === ModeEnum.EXPLAIN ? (
          <>
            <Menu.Label>Explain Tool Preferred LLM</Menu.Label>
            <ScrollArea type="auto" h={270}>
              {explainModelChoices.map(({ label, value }) => {
                const isPreferredExplainLlm = userSettings?.preferred_explain_llm === value;

                return (
                  <Menu.Item
                    key={value}
                    leftSection={getLlmIcon(value)}
                    rightSection={isPreferredExplainLlm ? <IconCheck /> : null}
                    onClick={() => handleUpdateUserSettings({ preferred_explain_llm: value })}
                  >
                    <span className={isPreferredExplainLlm ? "font-semibold" : ""}>{label}</span>
                  </Menu.Item>
                );
              })}
            </ScrollArea>
          </>
        ) : null}
        {mode === ModeEnum.MARKUP ? (
          <>
            <Menu.Label>Markup Tool Preferred LLM</Menu.Label>
            {markupModelChoices.map(({ label, value }) => {
              const isPreferredMarkupLlm = userSettings?.preferred_markup_llm === value;
              return (
                <Menu.Item
                  key={value}
                  leftSection={getLlmIcon(value)}
                  rightSection={isPreferredMarkupLlm ? <IconCheck /> : null}
                  onClick={() =>
                    handleUpdateUserSettings({
                      preferred_markup_llm: value,
                      reloadSuggestions: true,
                    })
                  }
                >
                  <span className={isPreferredMarkupLlm ? "font-semibold" : ""}>{label}</span>
                </Menu.Item>
              );
            })}
          </>
        ) : null}
        {mode === ModeEnum.EXPLAIN ? (
          <>
            <Menu.Label>Explain Tool Chat Mode</Menu.Label>
            {explainChatModeChoices.map(({ label, value }) => {
              const isSelectedChatMode = (userSettings?.chat_mode ?? ChatMode.PRO_MODE) === value;

              return (
                <HoverCard
                  key={value}
                  width={400}
                  shadow="md"
                  openDelay={0}
                  closeDelay={0}
                  offset={20}
                >
                  <HoverCard.Target>
                    <Menu.Item
                      leftSection={chatModeIcons[value]}
                      rightSection={isSelectedChatMode ? <IconCheck /> : null}
                      onClick={() => handleUpdateUserSettings({ chat_mode: value })}
                    >
                      <span className={isSelectedChatMode ? "font-semibold" : ""}>{label}</span>
                    </Menu.Item>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <div className="p-4">
                      <div className="text-lg font-semibold">
                        {chatModeHoverInfo[value as ChatMode].title}
                      </div>
                      <div className="text-sm text-gray-600 mt-2">
                        {chatModeHoverInfo[value as ChatMode].description}
                      </div>
                    </div>
                  </HoverCard.Dropdown>
                </HoverCard>
              );
            })}
          </>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  );
});

export default ChatSettings;
