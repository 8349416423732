import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Text } from "@mantine/core";
import { LocalStorageService } from "@/services";
import { notifications } from "@mantine/notifications";
import { useLazyLogoutQuery } from "@/redux/api";

interface SessionTimeoutProviderProps {
  children: React.ReactNode;
}

const FIFTEEN_MINUTES_MS = 15 * 60 * 1000;
const ONE_SECOND_MS = 1000;
const ONE_MINUTE_MS = 60 * 1000;

const SessionTimeoutProvider = ({ children }: SessionTimeoutProviderProps) => {
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [showCountdown, setShowCountdown] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const [logout] = useLazyLogoutQuery();

  const navigate = useNavigate();

  useEffect(() => {
    let countdownIntervalId: NodeJS.Timeout | null = null;

    const clearCountdownInterval = () => {
      if (countdownIntervalId) {
        clearInterval(countdownIntervalId);
        countdownIntervalId = null;
      }
    };

    const handleTokenExpiry = () => {
      setSessionExpired(true);
      setShowCountdown(false);
      setTimeRemaining(null);
      clearCountdownInterval();
    };

    const checkTokenExpiry = () => {
      const expireTimestamp = LocalStorageService.getAuthTokenExpiry();

      if (!expireTimestamp) {
        // No token expiry timestamp available
        setSessionExpired(false);
        setShowCountdown(false);
        setTimeRemaining(null);
        clearCountdownInterval();
        return;
      }

      const nowMs = Date.now(); // In milliseconds
      const expiresAtMs = expireTimestamp * 1000; // In milliseconds
      const timeLeftMs = expiresAtMs - nowMs;

      if (timeLeftMs <= 0) {
        // Token has already expired
        handleTokenExpiry();
      } else if (timeLeftMs <= FIFTEEN_MINUTES_MS) {
        // Less than 15 minutes remaining
        setShowCountdown(true);
        setTimeRemaining(timeLeftMs);

        // Start or continue countdown interval if not already running
        if (!countdownIntervalId) {
          countdownIntervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
              if (!prevTime) {
                return null;
              }
              const newTime = prevTime - ONE_SECOND_MS;
              if (newTime <= 0) {
                // Time has run out
                handleTokenExpiry();
                return null;
              }
              return newTime;
            });
          }, ONE_SECOND_MS);
        }
      } else {
        // More than 15 minutes remaining
        setShowCountdown(false);
        setTimeRemaining(null);
        clearCountdownInterval();
      }
    };

    // Initial check
    checkTokenExpiry();

    // Set interval to check token expiry every minute
    const tokenCheckIntervalId = setInterval(() => {
      checkTokenExpiry();
    }, ONE_MINUTE_MS);

    // Cleanup function
    return () => {
      // Clear intervals on component unmount
      if (countdownIntervalId) {
        clearInterval(countdownIntervalId);
      }
      clearInterval(tokenCheckIntervalId);
    };
  }, []);

  // Helper function to format time remaining into "X minutes Y seconds"
  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.max(0, Math.floor(milliseconds / 1000));
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ${seconds} second${seconds !== 1 ? "s" : ""}`;
  };

  // TODO(Reinis,Aleksey): This is copy-pasted from another place. Refactor to use a common function.
  const handleLogOut = async () => {
    try {
      if (!sessionExpired) {
        await logout(undefined);
      }

      notifications.show({
        title: "Sign out successful.",
        message: "You have successfully signed out. Farewell.",
        color: "green",
      });

      LocalStorageService.removeAuthToken();
      LocalStorageService.removeMeta();
      LocalStorageService.removeSelectedDocumentId();

      navigate("/");
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  return (
    <>
      {showCountdown && timeRemaining !== null && (
        <Modal
          title={
            <Text fw={500} size="lg">
              Your session is about to expire!
            </Text>
          }
          size="md"
          padding="md"
          centered={true}
          closeOnClickOutside={false}
          withCloseButton={false}
          opened={showCountdown && timeRemaining !== null}
          onClose={() => setShowCountdown(false)}
        >
          <div className="flex flex-col gap-4">
            <Text size="md">
              Your session will expire in: <strong>{formatTime(timeRemaining)}</strong>.
            </Text>
            <Text size="sm">
              You will be unable to continue your work once your session expires.{" "}
              <strong>Please sign out and sign back in to continue.</strong>
            </Text>
            <div className="flex flex-row justify-end gap-4">
              <Button variant="outline" color="violet" onClick={() => setShowCountdown(false)}>
                Continue work for now
              </Button>
              <Button
                color="violet"
                onClick={() => {
                  setShowCountdown(false);
                  handleLogOut();
                }}
              >
                Sign Out now
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {sessionExpired && (
        <Modal
          title={
            <Text fw={500} size="lg">
              Session Expired!
            </Text>
          }
          size="md"
          padding="md"
          centered={true}
          closeOnClickOutside={false}
          withCloseButton={false}
          opened={sessionExpired}
          onClose={() => setSessionExpired(false)}
        >
          <div className="flex flex-col gap-4">
            <Text size="md">
              Your session has expired. Please sign in again to continue your work.
            </Text>
            <div className="flex flex-row justify-end gap-4">
              <Button
                color="violet"
                onClick={() => {
                  setSessionExpired(false);
                  handleLogOut();
                }}
              >
                Back to Homepage
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {children}
    </>
  );
};

export default SessionTimeoutProvider;
