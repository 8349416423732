import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import {
  IChatNotification,
  IChatNotificationWithId,
  Notification,
  NotificationStatus,
} from "@common/types";
import { documentsSlice } from "@/redux/slices/documents.ts";

interface NotificationsState {
  chatNotification: Record<string, IChatNotification | null>;
  notifications: Notification[];
  selectedDocumentId?: string;
  status: NotificationStatus;
}

const initialState: NotificationsState = {
  chatNotification: {},
  notifications: [],
  status: NotificationStatus.ghost,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setChatNotification: (state, { payload }: PayloadAction<IChatNotificationWithId>) => {
      if (payload?.id) {
        state.chatNotification = {
          ...state.chatNotification,
          [payload.id]: payload,
        };
      }
    },
    pushNotification: (state, { payload }) => {
      const timestamp: number = Date.now();

      const newNotification = {
        id: timestamp,
        timestamp,
        ...payload,
      };

      state.notifications = [...state.notifications, newNotification];

      const statusPriority: Record<NotificationStatus, number> = {
        ghost: 0,
        info: 1,
        success: 2,
        warning: 3,
        error: 4,
      };

      let highestStatus: NotificationStatus = NotificationStatus.ghost;

      for (const notification of state.notifications) {
        if (statusPriority[notification.type] > statusPriority[highestStatus]) {
          highestStatus = notification.type as NotificationStatus;
        }
      }

      state.status = highestStatus;
    },
  },
  selectors: {
    getChatErrorNotification: ({ chatNotification }): IChatNotification | null =>
      chatNotification["error"],
    getChatNotificationById: createSelector(
      (state) => state.chatNotification,
      (_, id) => id,
      (chatNotification, id) => chatNotification[id]
    ),
  },
  extraReducers: (builder) => {
    builder.addCase(documentsSlice.actions.setSelectedDocument, (state, action) => {
      state.selectedDocumentId = action.payload?.id;
    });
  },
});

export const { pushNotification, setChatNotification } = notificationsSlice.actions;

export const { getChatErrorNotification, getChatNotificationById } = notificationsSlice.selectors;

export default notificationsSlice.reducer;
