import { ProvidersService } from "@/services";
import { useNavigate } from "react-router-dom";

const ConfirmProvider = () => {
  const navigate = useNavigate();

  const handleRedirectToApp = () => {
    ProvidersService.processProviderCallback();
    navigate("/rio/dashboard/files");
  };

  return (
    <div className="grid grid-rows-[auto_1fr] h-dvh w-dvw p-16">
      <div className="place-self-center flex flex-col justify-center items-center gap-8">
        <div className="text-2xl">Provider has been connected successfully.</div>
        <button
          className="bg-indigo-600 px-4 py-2 text-white font-semibold"
          onClick={handleRedirectToApp}
        >
          GO TO HOMEPAGE
        </button>
      </div>
    </div>
  );
};

export default ConfirmProvider;
