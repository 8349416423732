import React, { useEffect, useMemo, useRef } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "@/redux/hooks.ts";
import { getSortOrder, setSortOrder } from "@/redux/slices";
import { useForm, useWatch } from "react-hook-form";
import { Button, Checkbox, Loader, ScrollArea, Tabs as MantineTabs } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconDownload, IconTrash } from "@tabler/icons-react";
import { EStorageFileType, FileTreeItem, SelectedFileTreeItem } from "@common/types";
import {
  useDeleteDocumentMutation,
  useDeleteDocumentFolderMutation,
  useGetUserFileTreeQuery,
  useLazyGetDocumentBlobQuery,
} from "@/redux/api";
import { Top } from "@/components/Rio/components/Dashboard/components/Top/Top.tsx";
import { Folders, File, Tabs } from "@/components/Rio/components/Dashboard/components";
import { useSelector } from "react-redux";
import { selectDocument } from "@/redux/thunk";

dayjs.extend(localizedFormat);
dayjs.extend(utc);

type SelectedRecord = Record<string, SelectedFileTreeItem>;

type FormValues = {
  search: string;
  selected: SelectedRecord;
};

const deletionWarningText =
  "Make sure to download and back up your chat history beforehand if you would like to save it.";

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  const sortOrder = useSelector(getSortOrder);

  const navigate = useNavigate();

  const { entity } = useParams();

  const documentsEntities = useRef<{
    files: FileTreeItem[];
    collections: FileTreeItem[];
    collectionsSearchMap: Record<string, string[]>;
  }>({ files: [], collections: [], collectionsSearchMap: {} as Record<string, string[]> });

  const {
    data: documents,
    isFetching: isUserTreeFetching,
    isSuccess: isUserFileTreeSuccess,
    fulfilledTimeStamp: getUserFileTreeFulfilledTimeStamp,
  } = useGetUserFileTreeQuery();

  const [deleteDocument, { isLoading: isDeleting }] = useDeleteDocumentMutation();

  const [deleteFolder, { isLoading: isFolderDeleting }] = useDeleteDocumentFolderMutation();

  const [getDocumentBlob] = useLazyGetDocumentBlobQuery();

  const { control, register, reset, getValues, setValue } = useForm<FormValues>({
    defaultValues: {
      selected: {},
    },
  });

  const search = useWatch({
    control,
    name: "search",
    defaultValue: "",
  });

  const selectedWatch = useWatch({ control, name: "selected" });

  useEffect(() => {
    if (!entity || (entity !== "files" && entity !== "folders")) {
      navigate("/rio/dashboard/files");
    }
  }, [entity, navigate]);

  useEffect(() => {
    if (isUserFileTreeSuccess) {
      const selectionsMap = documents.reduce((acc, file) => {
        const { id, children } = file;
        acc[id] = { ...file, isSelected: false };

        if (Array.isArray(children)) {
          children.forEach((child) => {
            acc[child.id] = { ...child, isSelected: false };
          });
        }

        return acc;
      }, {} as SelectedRecord);

      setValue("selected", selectionsMap);
    }
  }, [isUserFileTreeSuccess, getUserFileTreeFulfilledTimeStamp]); // eslint-disable-line react-hooks/exhaustive-deps

  documentsEntities.current = useMemo(
    () =>
      (documents?.slice() || []).reduce<{
        files: FileTreeItem[];
        collections: FileTreeItem[];
        collectionsSearchMap: Record<string, string[]>;
      }>(
        (acc, curr) => {
          if (curr.children) {
            acc.collections.push(curr);
            acc.collectionsSearchMap = {
              ...acc.collectionsSearchMap,
              [curr.id]: [
                curr.name.toLowerCase(),
                ...curr.children.map((child) => child.name.toLowerCase()),
              ],
            };
          } else {
            acc.files.push(curr);
          }

          return acc;
        },
        { files: [], collections: [], collectionsSearchMap: {} }
      ),
    [documents]
  );

  const checkIsFilesSelected = () =>
    selectedWatch ? Object.values(selectedWatch).some(({ isSelected }) => isSelected) : false;

  const handleSelectionChange = (
    id: string,
    parentFolderId: string | null,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;
    const selected = getValues("selected");

    if (id) {
      if (parentFolderId) {
        const childrenOfParent = documentsEntities.current.collections.find(
          (collection) => collection.id === parentFolderId
        )?.children;

        if (childrenOfParent) {
          const isEveryChildSelected = childrenOfParent.every(
            (child) => selected[child.id].isSelected
          );

          setValue(`selected.${parentFolderId}.isSelected`, isEveryChildSelected);
        }
      } else {
        const collectionChildren = documentsEntities.current.collections.find(
          (collection) => collection.id === id
        )?.children;

        if (collectionChildren) {
          collectionChildren.forEach(({ id }) => {
            setValue(`selected.${id}.isSelected`, checked);
          });
        }
      }
    }
  };

  const handleSortChange = (value: string) => {
    dispatch(setSortOrder(value));
  };

  const handleDelete = async ({ children, parent_folder_id, id }: FileTreeItem) => {
    if (isDeleting || isUserTreeFetching) {
      return;
    }

    modals.openConfirmModal({
      title: <span className="text-xl">Delete {children ? "Folder" : "Document"}</span>,
      children: (
        <div className="flex flex-col gap-2">
          {children ? (
            <p>Are you sure you would like to delete this folder?</p>
          ) : parent_folder_id ? (
            <>
              <p>Are you sure you would like to delete this document?</p>
              <p>
                Note that <strong>chat history for the remaining folder will be reset</strong>, even
                if you still have other documents in the folder.
              </p>
            </>
          ) : (
            <p>Are you sure you would like to delete this document?</p>
          )}
          <p>{deletionWarningText}</p>
        </div>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "violet" },
      onConfirm: async () => {
        if (children?.length) {
          await deleteFolder({ id });
        } else {
          if (parent_folder_id) {
            await deleteDocument({ id, parentId: parent_folder_id });
          } else {
            await deleteDocument({ id });
          }
        }
      },
    });
  };

  const handleDocumentClick = (document: FileTreeItem) => {
    if (document) {
      const { id, collection_id } = document;

      dispatch(selectDocument({ id, collection_id }));
      navigate("/rio");
    }
  };

  const handleDownload = async (document: FileTreeItem) => {
    const { id } = document?.children?.[0] ?? document;
    const redactedExtension = document?.document_redacted_extension;

    await getDocumentBlob({
      id,
      name: `${document.name}${redactedExtension || document.document_original_extension}`,
      storage_file_type: redactedExtension ? EStorageFileType.REDACTED : EStorageFileType.ORIGINAL,
    });
  };

  const handleDeleteSelected = () => {
    // @ts-expect-error : TS2615: Type of property children circularly references itself in mapped type
    const selectedValues = Object.values(getValues("selected"));

    const [selectedParents, selectedChildren] = selectedValues.reduce(
      (acc, file) => {
        const { children, isSelected, parent_folder_id } = file;

        if (isSelected) {
          if (parent_folder_id || !Array.isArray(children)) {
            acc[1].push(file);
          } else {
            acc[0].push(file);
          }
        }

        return acc;
      },
      [[], []] as [FileTreeItem[], FileTreeItem[]]
    );

    const parentsForDeletion = new Set(selectedParents.map(({ id }) => id));

    const childrenForDeletion = new Set(
      selectedChildren.reduce((acc, { id, parent_folder_id }) => {
        if (parent_folder_id) {
          if (!parentsForDeletion.has(parent_folder_id)) {
            acc.push(id);
          }
        } else {
          acc.push(id);
        }

        return acc;
      }, [] as string[])
    );

    if (parentsForDeletion.size || childrenForDeletion.size) {
      modals.openConfirmModal({
        title: <span className="text-xl">Delete Selected Files</span>,
        children: (
          <div className="flex flex-col gap-2">
            <p>Are you sure you would like to delete these selected files?</p>
            <p>{deletionWarningText}</p>
          </div>
        ),
        labels: { confirm: "Delete", cancel: "Cancel" },
        confirmProps: { color: "violet" },
        onConfirm: async () => {
          await Promise.all(Array.from(parentsForDeletion).map((id) => deleteFolder({ id })));
          await Promise.all(
            Array.from(childrenForDeletion).map((id) => deleteDocument({ id, parentId: "LIST" }))
          );

          reset();
        },
      });
    }
  };

  const handleSelectAll = (event: React.FormEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;

    if (entity === "folders") {
      documentsEntities.current.collections.forEach((collection) => {
        setValue(`selected.${collection.id}.isSelected`, checked);
        collection.children?.forEach((child) => {
          setValue(`selected.${child.id}.isSelected`, checked);
        });
      });
    } else {
      documentsEntities.current.files.forEach((file) => {
        setValue(`selected.${file.id}.isSelected`, checked);
      });
    }
  };

  const sortFn = (
    { created_at, name, updated_at }: FileTreeItem,
    { created_at: created_at_next, name: name_next, updated_at: updated_at_next }: FileTreeItem
  ) => {
    const isAsc = sortOrder.endsWith("asc");

    switch (sortOrder) {
      case "name-asc":
      case "name-desc": {
        const nameA = name.toUpperCase();
        const nameB = name_next.toUpperCase();

        if (nameA < nameB) {
          return isAsc ? -1 : 1;
        }
        if (nameA > nameB) {
          return isAsc ? 1 : -1;
        }

        return 0;
      }
      case "edited-asc":
      case "edited-desc": {
        const current = dayjs(updated_at).unix();
        const next = dayjs(updated_at_next).unix();

        return isAsc ? current - next : next - current;
      }
      case "uploaded-asc":
      case "uploaded-desc": {
        const current = dayjs(created_at).unix();
        const next = dayjs(created_at_next).unix();

        return isAsc ? current - next : next - current;
      }
    }
  };

  return (
    <>
      <MantineTabs
        value={entity}
        color="#8b5cf6"
        className="w-full"
        onChange={(value) => navigate(`/rio/dashboard/${value}`, { replace: true })}
      >
        <div className="grid grid-rows-[6rem_5rem_5rem_1fr] h-full w-full px-16">
          <Top />
          <Tabs control={control} onSortChange={handleSortChange} />
          <div
            className="flex flex-row justify-between items-center gap-4
          p-4 mb-0.5"
          >
            <div className="flex flex-row justify-center items-center gap-4">
              <Checkbox
                variant="outline"
                size="lg"
                label={<span className="text-base">Select all</span>}
                onChange={handleSelectAll}
              />
              <Button
                disabled
                variant="transparent"
                size="md"
                aria-label="Download"
                rightSection={<IconDownload stroke={1.5} />}
              >
                Download Selected
              </Button>
              <Button
                color="#8b5cf6"
                variant="transparent"
                size="md"
                aria-label="Delete"
                rightSection={<IconTrash stroke={1.5} />}
                disabled={!checkIsFilesSelected() || isDeleting || isUserTreeFetching}
                onClick={handleDeleteSelected}
              >
                Delete Selected
              </Button>
            </div>
            {isDeleting || isFolderDeleting ? <Loader type="dots" color="#8b5cf6" /> : null}
          </div>
          <div className="overflow-scroll">
            <MantineTabs.Panel value="files">
              <ScrollArea h="100%">
                {isUserFileTreeSuccess
                  ? documentsEntities.current.files
                      .sort(sortFn)
                      .filter((document) =>
                        document.name.toLowerCase().includes(search.toLowerCase())
                      )
                      .map((document) => (
                        <File
                          key={document.id}
                          document={document}
                          register={register}
                          onDeleteClick={handleDelete}
                          onDocumentClick={handleDocumentClick}
                          onDownloadClick={handleDownload}
                        />
                      ))
                  : null}
              </ScrollArea>
            </MantineTabs.Panel>
            <MantineTabs.Panel value="folders">
              <ScrollArea h="100%">
                {documentsEntities.current.collections ? (
                  <Folders
                    data={documentsEntities.current.collections
                      .sort(sortFn)
                      .filter((document) =>
                        search
                          ? documentsEntities.current.collectionsSearchMap[document.id].find(
                              (name) => name.includes(search.toLowerCase())
                            )
                          : true
                      )}
                    register={register}
                    onDelete={handleDelete}
                    onDocumentClick={handleDocumentClick}
                    onDownload={handleDownload}
                    onSelectionChange={handleSelectionChange}
                  />
                ) : null}
              </ScrollArea>
            </MantineTabs.Panel>
          </div>
        </div>
      </MantineTabs>
    </>
  );
};

export default Dashboard;
