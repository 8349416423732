export enum FetchingStatus {
  failed = "failed",
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
}

export type TApplicationMode = "explain" | "markup";

export enum ThunkState {
  failed = "failed",
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
}

export enum UploadStatus {
  choosing = "choosing",
  uploading = "uploading",
  indexing = "indexing",
  done = "done",
}

export enum NotificationStatus {
  error = "error",
  info = "info",
  ghost = "ghost",
  success = "success",
  warning = "warning",
}

export enum ModeEnum {
  MARKUP = "markup",
  EXPLAIN = "explain",
  SUMMARY = "summary",
}
