import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  MantineColorScheme,
  SegmentedControl,
  TextInput,
  Select,
  useMantineColorScheme,
  Loader,
} from "@mantine/core";
import { IconEdit, IconLogout } from "@tabler/icons-react";
import { useGetAppSettingsQuery, useLazyLogoutQuery } from "@/redux/api";
import { notifications } from "@mantine/notifications";
import { LocalStorageService } from "@/services";
import { useGetUserDataQuery, useUpdateUserDataMutation } from "@/redux/api";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts";
import { getFontSize, setFontSize } from "@/redux/slices";

const Settings: React.FC = () => {
  const navigate = useNavigate();

  const fontSize = useAppSelector(getFontSize);

  const dispatch = useAppDispatch();

  const [logout] = useLazyLogoutQuery();

  const { data: appSettings, isLoading: isAppSettingsLoading } = useGetAppSettingsQuery(undefined);

  const { data: user } = useGetUserDataQuery(undefined);

  const [updateUserData] = useUpdateUserDataMutation();

  const { colorScheme, setColorScheme } = useMantineColorScheme();

  const [isOnlyOfficeEnabled, setIsOnlyOfficeEnabled] =
    LocalStorageService.useUserPreference<boolean>(
      LocalStorageService.IS_ONLYOFFICE_ENABLED_KEY,
      false
    );

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{
    fullName: string;
  }>();

  const handleFontSizeChange = (fontSize: string | null) => {
    if (fontSize) dispatch(setFontSize(fontSize));
  };

  const handleLogOut = async () => {
    try {
      await logout(undefined);

      notifications.show({
        title: "Sign out successful.",
        message: "You have successfully signed out. Farewell.",
        color: "green",
      });

      LocalStorageService.removeAuthToken();
      LocalStorageService.removeMeta();
      LocalStorageService.removeSelectedDocumentId();

      navigate("/");
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  const handleUserNameChange = async () => {
    await updateUserData({ updated_full_name: getValues("fullName") });

    notifications.show({
      title: "Settings updated",
      message: `User name has been updated.`,
      color: "green",
    });
  };

  const handleThemeChange = (value: MantineColorScheme | string) => {
    setColorScheme(value as MantineColorScheme);
  };

  return (
    <div className="flex flex-row justify-center h-full w-full">
      <div className="flex flex-row justify-center h-full w-8/12 py-16">
        <div className="flex flex-col justify-start items-center gap-4 w-full h-full">
          <div className="flex flex-row justify-between items-center gap-4 w-full">
            <div className="flex flex-row justify-start items-center gap-4">
              <h1 className="font-semibold text-3xl">Settings</h1>
              {isAppSettingsLoading ? <Loader size="sm" /> : null}
            </div>
            <div className="flex flex-row justify-between items-center gap-2">
              <h2 className="font-semibold text-xl">Logout</h2>
              <ActionIcon
                variant="white"
                radius={50}
                size="lg"
                aria-label="logout"
                onClick={handleLogOut}
              >
                <IconLogout style={{ width: "70%", height: "70%" }} color="black" stroke={1.5} />
              </ActionIcon>
            </div>
          </div>
          <div className="grid grid-cols-2 grid-rows-3 gap-8 w-full mt-24">
            <div className="mr-24">
              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label="NAME"
                    size="lg"
                    defaultValue={user?.full_name ?? undefined}
                    rightSection={
                      <ActionIcon
                        variant="transparent"
                        onClick={handleSubmit(handleUserNameChange)}
                      >
                        <IconEdit />
                      </ActionIcon>
                    }
                    error={errors.fullName?.type === "required" && "Full name is required"}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
            <div className="mr-24">
              <TextInput label="EMAIL" size="lg" defaultValue={user?.email} variant="unstyled" />
            </div>
            <div className="flex flex-col justify-start items-start">
              <span className="text-lg">THEME</span>
              <SegmentedControl
                data={[
                  { value: "light", label: <span>Light</span> },
                  { value: "dark", label: <span>Dark</span> },
                  { value: "system", label: <span>System</span> },
                ]}
                size="lg"
                value={colorScheme}
                onChange={handleThemeChange}
                disabled
              />
            </div>
            <div className="flex flex-col justify-start items-start">
              <div className="flex flex-row justify-center items-center gap-2">
                <span className="text-lg">FONT SIZE</span>
              </div>
              <Select
                size="lg"
                placeholder="Pick value"
                data={[
                  { value: "sm", label: "Small" },
                  { value: "base", label: "Normal" },
                  { value: "lg", label: "Large" },
                  { value: "xl", label: "Extra Large" },
                ]}
                defaultValue={fontSize}
                onChange={handleFontSizeChange}
              />
            </div>
            <div className="mr-24">
              {appSettings?.FEATURE_ONLYOFFICE_ENABLED ? (
                <div className="flex flex-col justify-start items-start">
                  <span className="text-lg">DOCX EDITOR</span>
                  <SegmentedControl
                    data={[
                      { value: "true", label: <span>Enabled</span> },
                      { value: "false", label: <span>Disabled</span> },
                    ]}
                    size="lg"
                    value={isOnlyOfficeEnabled ? "true" : "false"}
                    onChange={(value) => setIsOnlyOfficeEnabled(value === "true")}
                  />
                </div>
              ) : null}
            </div>
            <div className="mr-24">
              {/*<TextInput label="PAYMENT METHOD" size="lg" radius="md" disabled />*/}
            </div>
            <div className="mr-24">
              {/*<Select*/}
              {/*  label="NOTIFICATIONS"*/}
              {/*  data={["All", "Important", "None"]}*/}
              {/*  defaultValue="All"*/}
              {/*  size="lg"*/}
              {/*  disabled*/}
              {/*/>*/}
            </div>
            <div>
              {/*<Button size="lg" color="gray" disabled>*/}
              {/*  Add payment method*/}
              {/*</Button>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
