import { jwtDecode } from "jwt-decode";
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { LocalStorageService } from "@/services";

const endpointsWithoutUnauthorizedRedirect = [
  "getUserData",
  "resetPassword",
  "requestResetPassword",
  "signIn",
  "signUp",
];

const cleanAuthorizationAndRedirect = () => {
  LocalStorageService.removeAuthToken();
  LocalStorageService.removeMeta();
  LocalStorageService.removeSelectedDocumentId();

  if (!window.location.href.endsWith("/sign-in")) {
    window.location.href = "/sign-in";
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: "/api",
  prepareHeaders: (headers) => {
    const token = LocalStorageService.getAuthToken();
    const jwtExp = token ? jwtDecode(token).exp : 0;

    if (jwtExp && Date.now() < jwtExp * 1000) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithRedirect: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const { endpoint } = api;

    if (!endpointsWithoutUnauthorizedRedirect.includes(endpoint)) {
      cleanAuthorizationAndRedirect();
    }
  }

  return result;
};

export default createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithRedirect,
  tagTypes: [
    "Chat",
    "Criteria",
    "Documents",
    "OnlyOfficeToken",
    "FavoriteQuestions",
    "Review",
    "Settings",
    "Suggestions",
    "SuggestedQuestions",
    "User",
    "AppSettings",
  ],
  endpoints: () => ({}),
});
