import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setIsRedactView,
  setIsSelectedDocumentParent,
  setIsSelectedDocumentRedacted,
  setSelectedDocument,
} from "@/redux/slices/documents.ts";
import { FileTreeItem } from "@common/types";
import { normalizeResponseError } from "@/utils";
import { setChatNotification } from "@/redux/slices";
import { documentsApi } from "@/redux/api";
import { RootState } from "@/redux/store.ts";
import localStorage from "@/services/localStorage.ts";

const PREFIX = "documents";

export const selectDocument = createAsyncThunk(
  `${PREFIX}/selectDocument`,

  async (
    { id, collection_id: collectionId }: Pick<FileTreeItem, "id" | "collection_id">,
    { dispatch, getState }
  ) => {
    if (!collectionId) {
      return false;
    }

    try {
      const { data: documents } = documentsApi.endpoints.getUserFileTree.select(undefined)(
        <RootState>getState()
      );

      const relatedDocument = documents?.find(
        ({ collection_id }) => collection_id === collectionId
      );

      if (relatedDocument) {
        const isSelectedDocumentParent = !!relatedDocument.children?.length;

        dispatch(setIsSelectedDocumentParent(isSelectedDocumentParent));

        const selectedDocument = isSelectedDocumentParent
          ? relatedDocument.children!.find((child) => child.id === id) ??
            relatedDocument.children![0]
          : relatedDocument;

        dispatch(setSelectedDocument(selectedDocument));
        localStorage.setSelectedDocumentId(selectedDocument.id);

        const isSelectedDocumentRedacted =
          selectedDocument.is_redacted && !selectedDocument.is_scanned_pdf;

        dispatch(setIsSelectedDocumentRedacted(isSelectedDocumentRedacted));

        if (isSelectedDocumentRedacted) {
          dispatch(setIsRedactView(false));
        }

        dispatch(setChatNotification({ id: collectionId, message: null }));
      }

      return true;
    } catch (error: unknown) {
      throw new Error(normalizeResponseError(error, "Failed to select document"));
    }
  }
);
