import api from "./api.ts";

import { ApiPayload } from "@common/types";

export const dueDiligenceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    prepareDueDiligenceSummaries: builder.query({
      query: ({ collectionId }) => ({
        url: "due_diligence/prepare_due_diligence_summaries",
        method: "POST",
        params: {
          collection_id: collectionId,
        },
      }),
      transformResponse: (response: ApiPayload<string>) => response?.data ?? "",
    }),
  }),
});

export const {
  useLazyPrepareDueDiligenceSummariesQuery,
} = dueDiligenceApi;
