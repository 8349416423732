import api from "@/api.ts";

const getQueryParamsFromProviderUrl = () => {
  const parsedUrl = new URL(window.location.href);
  const params = new URLSearchParams(parsedUrl.search);

  const code = params.get("code");
  const state = params.get("state");
  const sessionState = params.get("session_state");

  return { code, state, sessionState };
};

const processProviderCallback = async () => {
  const { code, state, sessionState } = getQueryParamsFromProviderUrl();

  try {
    await api.get("/external-provider/sharepoint/auth_callback", {
      params: {
        code,
        state,
        session_state: sessionState,
      },
    });
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const handleProviderConnection = async (providerUrl: string) => {
  try {
    const { data } = await api.get(providerUrl);
    window.location.href = data.data.url;
  } catch (error) {
    console.error("Error during authorization:", error);
  }
};

export default {
  processProviderCallback,
  handleProviderConnection,
};
