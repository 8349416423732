import React from "react";
import { ProviderItem } from "@/components/CloudProvider/components";
import { Provider } from "@/common/types";

interface ProviderModalProps {
  providers: Provider[];
}

const ProviderModal: React.FC<ProviderModalProps> = ({ providers }) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        {providers.map(({ name, icon, redirectUrl }) => (
          <ProviderItem key={name} name={name} icon={icon} redirectUrl={redirectUrl} />
        ))}
      </div>
    </div>
  );
};

export default ProviderModal;
