import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconTallymark2 } from "@tabler/icons-react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import ReactPDFViewer from "@/components/ReactPDFViewer";
import { Explain, Review, OnlyOfficeViewer } from "@/components/Rio";
import { getIsRedactView, getMode, getSelectedDocument } from "@/redux/slices";
import { LocalStorageService } from "@/services";

import "react-reflex/styles.css";
import { useGetAppSettingsQuery, useGetUserDataQuery } from "@/redux/api";
import { Loader } from "@mantine/core";
import { useAppSelector } from "@/redux/hooks";
import Summary from "./Summary";
import { ModeEnum } from "@common/types";

const Layout = () => {
  const mode = useSelector(getMode);

  const [activeMediaQuery, setActiveMediaQuery] = useState<string | null>(null);

  const { data: appSettings, isLoading: isAppSettingsLoading } = useGetAppSettingsQuery(undefined);

  const { data: user } = useGetUserDataQuery(undefined);

  const [isOnlyOfficeViewerEnabledForUser] = LocalStorageService.useUserPreference<boolean>(
    LocalStorageService.IS_ONLYOFFICE_ENABLED_KEY,
    false
  );

  const selectedDocument = useAppSelector(getSelectedDocument);

  const isSelectedDocumentDocx =
    selectedDocument?.document_original_extension?.toLowerCase() === ".docx";

  const isRedactView = useAppSelector(getIsRedactView);

  const useOnlyOfficeViewer =
    isSelectedDocumentDocx &&
    !isRedactView &&
    user &&
    appSettings?.FEATURE_ONLYOFFICE_ENABLED &&
    isOnlyOfficeViewerEnabledForUser &&
    appSettings?.onlyoffice_document_server_url;

  useEffect(() => {
    const mediaQueryList: { [size: string]: MediaQueryList } = {
      xs: window.matchMedia("(max-width: 639px)"),
      sm: window.matchMedia("(min-width: 640px)"),
      md: window.matchMedia("(min-width: 768px)"),
      lg: window.matchMedia("(min-width: 1024px)"),
      xl: window.matchMedia("(min-width: 1280px)"),
    };

    const handleMediaQueryChange = () => {
      for (const size in mediaQueryList) {
        if (mediaQueryList[size].matches) {
          setActiveMediaQuery(size);
          break;
        }
      }
    };

    // Add listeners to each media query
    for (const size in mediaQueryList) {
      mediaQueryList[size].addEventListener("change", handleMediaQueryChange);
    }

    // Initial check
    handleMediaQueryChange();

    // Clean up listeners when component unmounts
    return () => {
      for (const size in mediaQueryList) {
        mediaQueryList[size].removeEventListener("change", handleMediaQueryChange);
      }
    };
  }, []);

  const tabContentMap = {
    [ModeEnum.MARKUP]: <Review />,
    [ModeEnum.EXPLAIN]: <Explain />,
    [ModeEnum.SUMMARY]: <Summary />,
  };

  return (
    <div className="h-full w-full">
      <ReflexContainer orientation={activeMediaQuery === "xs" ? "horizontal" : "vertical"}>
        <ReflexElement minSize={300} className="left-pane">
          {isAppSettingsLoading ? (
            <div className="flex justify-center items-center h-full">
              <Loader size="xl" />
            </div>
          ) : useOnlyOfficeViewer ? (
            // Without the span container, the UI may crash when switching between the two viewers
            // with an error: "Failed to execute 'removeChild' on 'Node'"
            <span>
              <OnlyOfficeViewer
                documentServerUrl={appSettings.onlyoffice_document_server_url}
                userData={user}
              />
            </span>
          ) : (
            <ReactPDFViewer />
          )}
        </ReflexElement>
        <ReflexSplitter className="!border-none !bg-transparent !w-2" style={{ zIndex: 10 }}>
          <div className="flex flex-col justify-center items-center h-full mr-4">
            <IconTallymark2 color="#8b5cf6" />
          </div>
        </ReflexSplitter>
        <ReflexElement size={500} maxSize={600} minSize={400} className="right-pane">
          <div className="flex flex-row w-full h-full">
            <div className="w-4 bg-neutral-100" />
            {tabContentMap[mode]}
          </div>
        </ReflexElement>
      </ReflexContainer>
    </div>
  );
};

export default Layout;
