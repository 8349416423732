import React from "react";
import { Button } from "@mantine/core";
import { Provider } from "@/common/types";
import { ProvidersService } from "@/services";

export const ProviderItem: React.FC<Provider> = ({ name, icon, redirectUrl }) => {
  return (
    <Button
      radius="md"
      size="md"
      onClick={() => ProvidersService.handleProviderConnection(redirectUrl)}
      leftSection={icon}
      variant="default"
      className="relative"
    >
      {name}
    </Button>
  );
};

export default ProviderItem;
