import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getSelectedDocument } from "@/redux/slices/documents.ts";
import { ChatHeader } from "./components";
import { getFontSize } from "@/redux/slices";
import { EFontSize } from "@common/types";
import { useLazyPrepareDueDiligenceSummariesQuery } from "@/redux/api";
import ReactMarkdown from "react-markdown";
import { Progress } from "@mantine/core";

const fontSizeClassMap: Record<EFontSize, string> = {
  xs: "prose-xs",
  sm: "prose-sm",
  base: "prose-base",
  lg: "prose-lg",
  xl: "prose-xl",
};

const Summary: React.FC = () => {
  const fontSize = useSelector(getFontSize);
  const selectedDocument = useSelector(getSelectedDocument);

  const [prepareDueDiligenceSummaries, { currentData: collectionSummary, isLoading, isFetching }] =
    useLazyPrepareDueDiligenceSummariesQuery();

  const { collection_id: collectionId } = selectedDocument ?? {};

  useEffect(() => {
    if (collectionId) {
      prepareDueDiligenceSummaries({ collectionId });
    }
  }, [collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="grid grid-rows-[auto_auto_1fr_auto] h-full min-h-full w-full min-w-full bg-gradient-to-br from-indigo-500 to-sky-400 rounded-l-xl">
      <ChatHeader />
      {(isLoading || isFetching) && (
        <div className="grid grid-cols-[1fr_auto] gap-2 mx-4 my-2 p-4 rounded-xl animate-[fade-in-keyframe_0.5s_ease-in-out] bg-white">
          <div>Loading...</div>
          <Progress value={100} animated className="col-span-2" />
        </div>
      )}
      <div
        className="
          flex flex-col gap-3 flex-1
          overflow-y-auto mx-4
          [-webkit-scrollbar]:w-0.5 [-webkit-scrollbar-thumb]:!bg-slate-400"
      >
        {collectionSummary && (
          <div className="bg-white p-2 rounded-tr-xl rounded-br-xl rounded-bl-xl">
            <div
              className={`bg-white p-2 rounded-tr-xl rounded-br-xl rounded-bl-xl prose ${fontSizeClassMap[(fontSize as EFontSize) || "base"]} prose-strong:text-[--mantine-color-text] text-[color:var(--mantine-color-text)] pl-1 py-1 [overflow-wrap:anywhere]`}
            >
              <ReactMarkdown>{collectionSummary}</ReactMarkdown>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
