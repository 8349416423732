import { isRejectedWithValue, configureStore } from "@reduxjs/toolkit";
import {
  documentsReducer,
  notificationsReducer,
  settingsReducer,
  qaReducer,
  setChatNotification,
} from "@/redux/slices";

import api from "@/redux/api";

import type { Middleware } from "@reduxjs/toolkit";
import { ApiErrorCodes, ApiPayload } from "@common/types";
import { LocalStorageService } from "@/services";

const apiErrorLogger: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const data = (action?.payload as { data: ApiPayload<string> })?.data;

    if (data && LocalStorageService.isJwtTokenExists()) {
      const errorCode: unknown = data.error_code;

      const message = errorCode
        ? ApiErrorCodes[errorCode as keyof typeof ApiErrorCodes]
        : data.message || ApiErrorCodes.UNEXPECTED_ERROR;

      api.dispatch(
        setChatNotification({
          id: "error",
          actions: ["reload", "close"],
          message,
          type: "error",
        })
      );
    } else {
      console.error(action);
    }
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    documents: documentsReducer,
    notifications: notificationsReducer,
    settings: settingsReducer,
    qa: qaReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, apiErrorLogger),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
